<template>
  <div class="card p-3 mt-4">
    <h2 class="text-xl font-semibold">Chưa được gắn tag</h2>
    <div>
        <div class="mt-4" v-if="emptyTagSongs.data.length">
          <song-item-upload v-for="song in emptyTagSongs.data" :song="song" prefix="bai-hat"
                            :key="'song' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyTagSongs.paginatorInfo.lastPage > 1"
              v-model="emptyTagSongs.paginatorInfo.currentPage"
              :pages="emptyTagSongs.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyTagSongs"
          />
        </div>
      </div>
  </div>
</template>

<script>
import SongItemUpload from "../song/SongItemUpload";
import ApiService from "../../core/services/api.service";
import {
  thousandFormat, getSongFieldsByType
} from "../../core/services/utils.service";

export default {
  name: "EmptyTagSongBox",
  components: {SongItemUpload},
  data() {
    return {
      emptyTagSongs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadEmptyTagSongs() {
      let songFields = getSongFieldsByType("song");

      let query = `query($page: Int) {
        emptyTagSongs(first: 10, page: $page, orderBy: [{column: "views", order: DESC}]) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            ${songFields}
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query, { page: this.emptyTagSongs.paginatorInfo.currentPage })
        .then(({ data }) => {
          if (data.data && data.data.emptyTagSongs) {
            this.emptyTagSongs = data.data.emptyTagSongs;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
  },
  mounted() {
    this.loadEmptyTagSongs();
  }
}
</script>
