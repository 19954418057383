<template>
  <div class="">
    <empty-tag-song-box/>
  </div>
</template>

<script>
import EmptyTagSongBox from "./EmptyTagSongBox.vue";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "EmptyTagSong",
  components: {EmptyTagSongBox},
  mounted() {
    changePageTitle("Cần bạn trợ giúp: Tag bài hát");
  }
}
</script>
